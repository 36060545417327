import React, { useState } from 'react';


const KinderErfassen = ({ formData, setFormData }) => {
 // const navigate = useNavigate();

  const [kinder, setKinder] = useState(formData.kinder || [
    {
      name: '',
      alter: '',
      schule: '',
      klasse: '',
      lehrer: '',
      lieblingsessen: '',
      isstNichtGerne: '',
      lieblingstaetigkeit: '',
      haustier: '',
      geschwister: '',
      instrument: '',
      wasUnsFreut: '',
      wasUnsWenigerFreut: '',
      wasWarBesser: '',
    },
  ]);

  const [setErrorMessage] = useState('');

  const handleChange = (index, event) => {
    const { name, value } = event.target;
    const newKinder = [...kinder];
    newKinder[index][name] = value;
    setKinder(newKinder);
    
    // Update the formData in the parent component
    setFormData((prevData) => ({
      ...prevData,
      kinder: newKinder,
    }));
  };

  const handleAddChild = () => {
    setKinder([
      ...kinder,
      {
        name: '',
        alter: '',
        schule: '',
        klasse: '',
        lehrer: '',
        lieblingsessen: '',
        isstNichtGerne: '',
        lieblingstaetigkeit: '',
        haustier: '',
        geschwister: '',
        instrument: '',
        wasUnsFreut: '',
        wasUnsWenigerFreut: '',
        wasWarBesser: '',
      },
    ]);
  };

  const handleRemoveChild = () => {
    if (kinder.length > 0) {
      const newKinder = kinder.slice(0, kinder.length - 1); // Entferne das letzte Kind
      setKinder(newKinder);
      
      // Update the formData in the parent component
      setFormData((prevData) => ({
        ...prevData,
        kinder: newKinder,
      }));
    }
  };


  const validateForm = () => {
    for (let kind of kinder) {
      if (!kind.name || !kind.alter) {
        setErrorMessage('Bitte füllen Sie alle Pflichtfelder aus.');
        return false;
      }
      if (isNaN(kind.alter) || kind.alter <= 0) {
        setErrorMessage('Das Alter muss eine positive Zahl sein.');
        return false;
      }
    }
    setErrorMessage(''); // Setze die Fehlermeldung zurück
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault(); // Verhindere die Standard-Formularübermittlung

    if (!validateForm()) {
      return; // Abbrechen, wenn die Validierung fehlschlägt
    }

    // Hier kannst du die Logik zum Speichern der Kinder in der JPA einfügen
    console.log('erfasste Kinder:', kinder);
    
  };

  return (
    <div className="container mt-5">
    <h1>Kinder erfassen</h1>
    <form onSubmit={handleSubmit}>
      {kinder.map((kind, index) => (
        <div key={index} className="mb-4 p-3 border rounded" style={{ backgroundColor: '#f9f9f9' }}>
          <div className="d-flex justify-content-between align-items-center"> {/* Flexbox für Titel und Löschen-Button */}
            <h3>Kind {index + 1}</h3>
            <button 
              type="button" 
              className="btn btn-danger btn-sm" 
              onClick={() => handleRemoveChild(index)} // Löschen des spezifischen Kindes
            >
              Löschen
            </button>
          </div>
            <div className="mb-3">
              <label htmlFor={`name-${index}`} className="form-label">Name*</label>
              <input
                type="text"
                className="form-control"
                id={`name-${index}`}
                name="name"
                placeholder="Name (Pflichtfeld)"
                value={kind.name}
                onChange={(event) => handleChange(index, event)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor={`alter-${index}`} className="form-label">Alter*</label>
              <input
                type="number"
                className="form-control"
                id={`alter-${index}`}
                name="alter"
                placeholder="Alter (Pflichtfeld)"
                value={kind.alter}
                onChange={(event) => handleChange(index, event)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor={`schule-${index}`} className="form-label">Name der Schule / Kindergarten</label>
              <input
                type="text"
                className="form-control"
                id={`schule-${index}`}
                name="schule"
                placeholder="Name der Schule / Kindergarten"
                value={kind.schule}
                onChange={(event) => handleChange(index, event)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor={`klasse-${index}`} className="form-label">Klasse</label>
              <input
                type="text"
                className="form-control"
                id={`klasse-${index}`}
                name="klasse"
                placeholder="Klasse"
                value={kind.klasse}
                onChange={(event) => handleChange(index, event)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor={`lehrer-${index}`} className="form-label">Lehrer/in</label>
              <input
                type="text"
                className="form-control"
                id={`lehrer-${index}`}
                name="lehrer"
                placeholder="Lehrer/in"
                value={kind.lehrer}
                onChange={(event) => handleChange(index, event)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor={`lieblingsessen-${index}`} className="form-label">Lieblingsessen</label>
              <input
                type="text"
                className="form-control"
                id={`lieblingsessen-${index}`}
                name="lieblingsessen"
                placeholder="Lieblingsessen"
                value={kind.lieblingsessen}
                onChange={(event) => handleChange(index, event)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor={`isstNichtGerne-${index}`} className="form-label">Isst nicht gerne</label>
              <input
                type="text"
                className="form-control"
                id={`isstNichtGerne-${index}`}
                name="isstNichtGerne"
                placeholder="Isst nicht gerne"
                value={kind.isstNichtGerne}
                onChange={(event) => handleChange(index, event)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor={`lieblingstaetigkeit-${index}`} className="form-label">Lieblingstätigkeit</label>
              <input
                type="text"
                className="form-control"
                id={`lieblingstaetigkeit-${index}`}
                name="lieblingstaetigkeit"
                placeholder="Lieblingstätigkeit"
                value={kind.lieblingstaetigkeit}
                onChange={(event) => handleChange(index, event)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor={`haustier-${index}`} className="form-label">Haustier</label>
              <input
                type="text"
                className="form-control"
                id={`haustier-${index}`}
                name="haustier"
                placeholder="Haustier"
                value={kind.haustier}
                onChange={(event) => handleChange(index, event)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor={`geschwister-${index}`} className="form-label">Bruder / Schwester von</label>
              <input
                type="text"
                className="form-control"
                id={`geschwister-${index}`}
                name="geschwister"
                placeholder="Bruder / Schwester von"
                value={kind.geschwister}
                onChange={(event) => handleChange(index, event)}
              />
            </div>
            <div className="mb-3">
              <label htmlFor={`instrument-${index}`} className="form-label">Instrument</label>
              <textarea                type="text"
                className="form-control"
                id={`instrument-${index}`}
                name="instrument"
                placeholder="Instrument"
                value={kind.instrument}
                rows="1"
                onChange={(event) => handleChange(index, event)}
                ></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor={`wasUnsFreut-${index}`} className="form-label">Was uns freut</label>
              <textarea
                type="text"
                className="form-control"
                id={`wasUnsFreut-${index}`}
                name="wasUnsFreut"
                placeholder="Was uns freut"
                value={kind.wasUnsFreut}
                rows="3"
                onChange={(event) => handleChange(index, event)}
                ></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor={`wasUnsWenigerFreut-${index}`} className="form-label">Was uns weniger freut</label>
              <textarea
                type="text"
                className="form-control"
                id={`wasUnsWenigerFreut-${index}`}
                name="wasUnsWenigerFreut"
                placeholder="Was uns weniger freut"
                value={kind.wasUnsWenigerFreut}
                rows="2"
                onChange={(event) => handleChange(index, event)}
              ></textarea>
            </div>
            <div className="mb-3">
              <label htmlFor={`wasWarBesser-${index}`} className="form-label">Was war besser als im letzten Jahr</label>
              <textarea
                type="text"
                className="form-control"
                id={`wasWarBesser-${index}`}
                name="wasWarBesser"
                placeholder="Was war besser als im letzten Jahr"
                value={kind.wasWarBesser}
                rows="3"  // Definiert die Höhe des Textbereichs
                onChange={(event) => handleChange(index, event)}
              ></textarea>
            </div>
          </div>
        ))}
        <button type="button" className="btn btn-success mb-3" onClick={handleAddChild}>
          <span>+</span> Kind hinzufügen
        </button>
        <br />
      </form>
    </div>
  );
};

export default KinderErfassen;
