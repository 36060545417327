import React from 'react';
import { Link } from 'react-router-dom'; // Hier importierst du Link
import './index.css'; // Hier kannst du deine CSS-Datei importieren

const ThankYou = () => {
  return (
    <div className="hero">
      <div className="container">
        <h1 className="display-4">Vielen Dank für Ihre Anmeldung!</h1>
        <p className="lead-bold">Wir haben Ihre Anmeldung erhalten und melden uns bald bei Ihnen.</p>
        <Link to="/" className="btn btn-light btn-lg btn-transparent">
          Startseite
        </Link>
        <p></p>
      </div>
      {/* Statische Kontaktleiste */}
      <div className="contact-bar">
        <p>
          Kontakt: &nbsp;
          <a href="mailto:samichlous@samichlous-belp.ch">samichlous@samichlous-belp.ch</a> |
          Telefon: <a href="tel:0787581651">078 758 16 51</a>
        </p>
      </div>
    </div>
  );
};

export default ThankYou;
