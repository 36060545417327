// src/Hero.js
import React from 'react';
import { Link } from 'react-router-dom'; // Hier importierst du Link
import './index.css';


const Hero = () => {
  return (
    <div className="hero">
      <div className="container">
        <h1 className="display-4">Willkommen bei der Samichlous-Gruppe Belp</h1>
         <p className="lead lead-bold"> 
          Auch dieses Jahr freuen wir uns darauf, wieder vielen Kindern mit dem Besuch des Samichlous eine Freude zu machen. Gemäss der Tradition besucht der Samichlous die Familien im Bischofsgewand, inspiriert von der Legende des Bischofs von Myra. Begleitet wird er von ein bis zwei Helfern und natürlich vom Schmutzli.<br /><br />
          Da die Samichlous-Aktion eine selbsttragende Organisation ist, die kostendeckend arbeiten muss, bitten wir Sie wie im letzten Jahr um einen Unkostenbeitrag von mindestens 20 Franken pro Besuch bzw. pro Familie.<br /><br />
          </p> <Link to="/FamilienBesuchAnmeldung" className="btn btn-light btn-lg btn-transparent">Familienbesuch Anmelden</Link>
          <br /><br />
        <p className="lead-bold">Unser Ziel ist es, Familien und Kinder in einer familiären Atmosphäre zu besuchen, damit Sie in der oft hektischen Adventszeit diesen besonderen Anlass mit Vorfreude und in würdevoller Stimmung geniessen können. Der Besuch des Samichlous soll Ihnen ausserdem die Gelegenheit bieten, wertvolle Zeit mit der Familie zu verbringen und zur Besinnung zu kommen.
        </p>
        <br /><br />
        <br /><br />
        <p className="lead-bold" > Möchtest du uns bei der Durchführung am 5. oder 6. Dezember als Helfer, Engel, Schmutzli, Samichlous oder hinter den Kulissen helfen?</p>
        <Link to="/Helfer" className="btn btn-light btn-lg btn-transparent">Helfer Anmeldung</Link>
        <br /><br />
        <br /><br />
        <br /><br />
        <br /><br />
      </div>  
      {/* Statische Kontaktleiste */}
      <div className="contact-bar">
        <p>
          Kontakt: &nbsp;
          <a href="mailto:samichlous@samichlous-belp.ch">samichlous@samichlous-belp.ch</a> | 
          Telefon: <a href="tel:0787581651">078 758 16 51</a>
        </p>
      </div>
    </div>
  );
};

export default Hero;
