import React from 'react'; 
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './index.css';
import Hero from './Hero';
import FamilienBesuchAnmeldung from './FamilienBesuchAnmeldung';
import KinderErfassen from './KinderErfassen';
import ThankYou from './ThankYou'; // Importiere die ThankYou-Komponente
import Helfer from './Helfer';
//import 'bootstrap/dist/css/bootstrap.min.css';


function App() { 
  return (
     <Router>
       <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/FamilienBesuchAnmeldung" element={<FamilienBesuchAnmeldung />} />
          <Route path="/Kind-Erfassen" element={<KinderErfassen />} /> {/* Route hinzufügen */}
          <Route path="/ThankYou" element={<ThankYou />} /> {/* Starte die ThankYou Seite*/}
          <Route path="/Helfer" element={<Helfer />} /> {/* Helfer-Seite */}
      </Routes>
     </Router>
   );
 }


export default App;
