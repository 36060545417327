// src/Anmeldung.js
import React, { useState } from 'react';
import KinderErfassen from './KinderErfassen'; // Importiere die Kindererfassen Komponente
//import { generatePDF } from './functions/PdfCreatorfromAnmeldung.js'; // PDF-Funktion importieren wird nur zum testen verwendet
//import testFormData from './testdata/testFormData'; // Importiere die Testdaten nur zum Testen
import { useNavigate } from 'react-router-dom';
import './index.css';


const FamilienbesuchAnmeldung = () => {
  
  // Richtig innerhalb der Komponente verwenden
  const navigate = useNavigate(); // Für die Navigation
  
  //Verwende die Testdaten für formData
  //const [formData, setFormData] = useState(testFormData);

//Verwenden für Operativ (/* */)
  const [formData, setFormData] = useState({
    vorname: '',
    nachname: '',
    strasse: '',
    etage: '', // Attribut für Etage
    plz: '',
    ortschaft: 'Belp',  // Standardmäßig Belp ausgewählt
    email: '',
    telefonnummer: '',  // Neues Feld für Telefonnummer
    ortDerGeschenke: '',  // Neues Feld für den Ort der Geschenke
    besuchstag: '',  // Besuchstag (5. Dezember, 6. Dezember, Beide Tage)
    wunschzeitStart: '',  // Wunschzeit Start
    wunschzeitEnd: '',  // Wunschzeit Ende
    bemerkungen: '',  // Neue Bemerkungen
    kinder: [] // Array für Kinder
  });
  

  const resetFormData = () => {
    setFormData({
      vorname: '',
      nachname: '',
      strasse: '',
      etage: '',
      plz: '',
      ortschaft: 'Belp',  // Setze zurück auf den Standardwert
      email: '',
      telefonnummer: '',
      ortDerGeschenke: '',
      besuchstag: '',
      wunschzeitStart: '',
      wunschzeitEnd: '',
      bemerkungen: '',
      kinder: [], // Leere Liste für Kinder
    });
  };

  // Funktion zum Anzeigen der bereits erfassten Kinder, wird nicht mehr benötigt..
  /*const displayChildren = () => {
    return formData.kinder.map((kind, index) => (
      <div key={index} className="mb-2">
        <p>Kind {index + 1}: {kind.name}, {kind.alter} Jahre</p>
      </div>
    ));
  };
*/


// Fehlerzustände für die Wunschzeiten und Telefonnummer
const [startError, setStartError] = useState('');
const [endError, setEndError] = useState('');
const [phoneError, setPhoneError] = useState('');  // Fehler für die Telefonnummer


const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  
  // Echtzeit-Überprüfung, wenn sich die Zeitfelder ändern
  if (e.target.name === 'wunschzeitStart' || e.target.name === 'wunschzeitEnd') {
    validateTimeRange(e.target.name, e.target.value);
  }


// Echtzeit-Überprüfung für Telefonnummer
if (name === 'telefonnummer') {
    validatePhoneNumber(value);
  }
};


  const validateTimeRange = (field, value) => {
    const start = field === 'wunschzeitStart' ? value : formData.wunschzeitStart;
    const end = field === 'wunschzeitEnd' ? value : formData.wunschzeitEnd;

    const minTime = '16:30';
    const maxTime = '20:00';

    // Validierung der Startzeit
    if (field === 'wunschzeitStart') {
      if (start < minTime || start > maxTime) {
        setStartError('Die Startzeit muss zwischen 16:30 und 20:00 liegen.');
      } else {
        setStartError('');
      }
    }

    // Validierung der Endzeit
    if (field === 'wunschzeitEnd') {
      if (end < minTime || end > maxTime) {
        setEndError('Die Endzeit muss zwischen 16:30 und 20:00 liegen.');
      } else if (start && end && start >= end) {
        setEndError('Die Endzeit muss nach der Startzeit liegen.');
      } else {
        setEndError('');
      }
    }
  };

  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^[0-9]{10}$/;  // Regex für genau 10 Ziffern

    if (!phoneRegex.test(phone)) {
      setPhoneError('Die Telefonnummer muss genau 10 Ziffern enthalten und darf keine Leerzeichen oder Sonderzeichen haben.');
    } else {
      setPhoneError('');
    }
  };

  const [emailStatus, setEmailStatus] = useState(''); // Statusnachricht für E-Mail-Versand
  const [loading, setLoading] = useState(false); // Zustand für den Ladevorgang

  const handleSubmit = async (e) => {
    e.preventDefault();  // Formular absenden und Kinder in JPA speichern

    console.log('Formular wird gesendet, formData:', formData);  // Konsolenausgabe vor dem Senden

// Ladezustand aktivieren
setLoading(true);
setEmailStatus('Daten werden gesendet...');

  // Lokales PDF wird erstellt nicht in Netlify
//generatePDF(formData);
 //console.log('Lokles PDF wurde erstellt und funktioniert als nächstets kommt das Email')
 

// Senden der E-Mail mit dem PDF-Anhang
  try {
    console.log('sendZohoEmail wird aufgefrufen')
    const response = await fetch('/.netlify/functions/sendZohoEmail', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',  // JSON-Format
      },
      body: JSON.stringify(formData),  // Formulardaten direkt als JSON übergeben
    });

  const result = await response.json();

      if (response.ok) {
        console.log('E-Mail erfolgreich gesendet:', result);
        setEmailStatus('E-Mail erfolgreich gesendet!'); // Erfolgsmeldung setzen
        //zurücksetzten des Status
        resetFormData(); 
        console.log('FormDaten sind zurückgesetzt')
        // Nach erfolgreichem Versand auf die ThankYou-Seite umleiten
    navigate('/ThankYou');  // Pfad zu deiner ThankYou-Seite
    console.log('navigate durchgeführt')
      } else {
        console.error('Fehler beim Senden der E-Mail:', result);
        setEmailStatus('Fehler beim Senden der E-Mail.'); // Fehlermeldung setzen
      }
    } catch (error) {
      console.error('Fehler beim E-Mail-Handler:', error);
      setEmailStatus('Fehler beim E-Mail-Handler.'); // Fehlermeldung setzen
    } finally {
      setLoading(false); // Ladezustand deaktivieren
    } 
    };    
    
  return (
    <div className="container mt-5">
    <h2>Familienbesuch Anmeldung</h2>
    <form onSubmit={handleSubmit}>
      <div className="mb-3">
        <label htmlFor="vorname" className="form-label"></label>
        <input
          type="hidden"
          className="form-control"
          id="vorname"
          name="vorname"
          value={formData.vorname}
          onChange={handleChange}
          required
        />
      <input type="hidden" name="form-name" value="familienbesuch-anmeldung" />
      </div>
      


{/* Kinderanzeige mit Rahmen, nur wenn Kinder erfasst sind */}
<div className="mb-3">
  <label className="form-label" style={{ fontWeight: 'bold' }}>Erfasste Kinder</label>
  <div className="kinder-container"> {/* Verwendung der neuen CSS-Klasse */}
    {formData.kinder.length > 0 ? (
      formData.kinder.map((kind, index) => (
        <p key={index} style={{ fontWeight: 'bold' }}>{kind.name}, {kind.alter} Jahre</p> // Fettdruck für den Kindertext
      ))
    ) : (
      <p style={{ fontStyle: 'italic', color: '#999' }}>Keine Kinder erfasst.</p> // Hinweistext im Container
    )}
  </div>
</div>

        <div className="mb-3">
          <label htmlFor="vorname" className="form-label">Vorname*</label>
          <input 
            type="text" 
            className="form-control" 
            id="vorname" 
            name="vorname" 
            value={formData.vorname} 
            onChange={handleChange} 
            required 
          />
        </div>

        <div className="mb-3">
          <label htmlFor="nachname" className="form-label">Nachname*</label>
          <input 
            type="text" 
            className="form-control" 
            id="nachname" 
            name="nachname" 
            value={formData.nachname} 
            onChange={handleChange} 
            required 
          />
        </div>

        <div className="mb-3">
          <label htmlFor="strasse" className="form-label">Strasse und Hausnummer*</label>
          <input 
            type="text" 
            className="form-control" 
            id="strasse" 
            name="strasse" 
            value={formData.strasse} 
            onChange={handleChange} 
            required 
          />
        </div>

{/* Etage */}
<div className="mb-3">
          <label htmlFor="etage" className="form-label">Etage</label>
          <input 
            type="text" 
            className="form-control" 
            id="etage" 
            name="etage" 
            value={formData.etage} 
            onChange={handleChange} 
            placeholder="z.B. 2. OG"  // Optionaler Platzhalter
          />
        </div>


        <div className="mb-3">
          <label htmlFor="plz" className="form-label">Postleitzahl*</label>
          <input 
            type="text" 
            className="form-control" 
            id="plz" 
            name="plz" 
            value={formData.plz} 
            onChange={handleChange} 
            required 
          />
        </div>

        <div className="mb-3">
          <label htmlFor="ortschaft" className="form-label">Ortschaft*</label>
          <select 
            className="form-select" 
            id="ortschaft" 
            name="ortschaft" 
            value={formData.ortschaft} 
            onChange={handleChange}
            required
          >
            <option value="Belp">Belp</option>
            <option value="Belpberg">Belpberg</option>
            <option value="Toffen">Toffen</option>
            <option value="Riggisberg">Riggisberg</option>
            <option value="Kaufdorf">Kaufdorf</option>
            <option value="Gelterfingen">Gelterfingen</option>
            <option value="Thurnen">Thurnen</option>
          </select>
        </div>

        <div className="mb-3">
          <label htmlFor="email" className="form-label">E-Mail*</label>
          <input 
            type="email"  // <--- Hier wird überprüft, ob es eine gültige E-Mail ist
            className="form-control" 
            id="email" 
            name="email" 
            value={formData.email} 
            onChange={handleChange} 
            required 
          />
           </div>

  {/* Telefonnummer */}
  <div className="mb-3">
          <label htmlFor="telefonnummer" className="form-label">Telefonnummer*</label>
          <input 
            type="text" 
            className="form-control" 
            id="telefonnummer" 
            name="telefonnummer" 
            value={formData.telefonnummer} 
            onChange={handleChange} 
            required 
          />
          {phoneError && (
            <div className="alert alert-danger mt-2" role="alert">
              {phoneError}
            </div>
          )}
        </div>

        {/* Ort der Geschenke */}
        <div className="mb-3">
          <label htmlFor="ortDerGeschenke" className="form-label">Ort der Geschenke* (z.B. Briefkasten)</label>
          <input 
            type="text" 
            className="form-control" 
            id="ortDerGeschenke" 
            name="ortDerGeschenke" 
            value={formData.ortDerGeschenke} 
            onChange={handleChange} 
            required 
          />
        </div>


 {/* Auswahl für Besuchstag */}
 <div className="mb-3">
          <label className="form-label">Besuchstag*</label>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="besuchstag" 
              id="besuchstag5" 
              value="5. Dezember" 
              onChange={handleChange} 
              required 
            />
            <label className="form-check-label" htmlFor="besuchstag5">
              5. Dezember
            </label>
          </div>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="besuchstag" 
              id="besuchstag6" 
              value="6. Dezember" 
              onChange={handleChange} 
              required 
            />
            <label className="form-check-label" htmlFor="besuchstag6">
              6. Dezember
            </label>
          </div>
          <div className="form-check">
            <input 
              className="form-check-input" 
              type="radio" 
              name="besuchstag" 
              id="besuchstagBeide" 
              value="Beide Tage sind möglich" 
              onChange={handleChange} 
              required 
            />
            <label className="form-check-label" htmlFor="besuchstagBeide">
              Beide Tage sind möglich
            </label>
          </div>
        </div>

   
        {/* Wunschzeit von-bis für den Besuch */}
        <div className="mb-3">
          <label htmlFor="wunschzeitStart" className="form-label">Wunschzeit* (Start) 16:30 Uhr</label>
          <input 
            type="time" 
            className="form-control" 
            id="wunschzeitStart" 
            name="wunschzeitStart" 
            value={formData.wunschzeitStart} 
            onChange={handleChange} 
            min="16:30" 
            max="20:00" 
            required 
          />
          {startError && (
            <div className="alert alert-danger mt-2" role="alert">
              {startError}
            </div>
          )}
        </div>
        
        <div className="mb-3">
          <label htmlFor="wunschzeitEnd" className="form-label">Wunschzeit* (Ende) 20:00 Uhr</label>
          <input 
            type="time" 
            className="form-control" 
            id="wunschzeitEnd" 
            name="wunschzeitEnd" 
            value={formData.wunschzeitEnd} 
            onChange={handleChange} 
            min="16:30" 
            max="20:00" 
            required 
          />
          {endError && (
            <div className="alert alert-danger mt-2" role="alert">
              {endError}
            </div>
          )}
        </div>

  {/* Bemerkungsfeld */}
 <div className="mb-3">
          <label htmlFor="bemerkungen" className="form-label">Bemerkungen / Informationen zum Besuch</label>
          <textarea 
            className="form-control" 
            id="bemerkungen" 
            name="bemerkungen" 
            value={formData.bemerkungen} 
            onChange={handleChange} 
            rows="3"  // Definiert die Höhe des Textbereichs
            placeholder="Zum Beispiel: Weitere Personen anwesend, besondere Wünsche oder Hinweise"
          ></textarea>
        </div>
 <KinderErfassen formData={formData} setFormData={setFormData} />
 <br></br>
{/* Submit-Button */}
<button type="submit" className="btn btn-primary" disabled={formData.kinder.length === 0 || loading}> 
 {loading ? 'Daten werden gesendet...' : 'Anmeldung absenden'}
        
        </button>
 {/* Ladesymbol anzeigen, wenn loading true ist */}
 {loading && <div className="spinner-border text-primary mt-3" role="status">
          <span className="sr-only">Loading...</span>
        </div>}
        {/* Hinweistext, wenn keine Kinder erfasst sind */}
        {formData.kinder.length === 0 && (
          <div className="alert alert-warning mt-3">
            Mindestens ein Kind muss erfasst werden!
          </div>
        )}
      </form>
      {/* Anzeige des E-Mail-Status unter dem Button */}
      {emailStatus && <div className="alert alert-info mt-3">{emailStatus}</div>}
      <br></br>
      <br></br>
      <br></br>
        </div>
  );
};

export default FamilienbesuchAnmeldung;
