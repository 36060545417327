import React from 'react';
import { Link } from 'react-router-dom'; // Hier importierst du Link
import './index.css'; // Hier kannst du deine CSS-Datei importieren

const Helfer = () => {
  return (
    <div className="hero">
      <div className="container">
        <h1 className="display-4">Helfer-Anmeldung</h1>
        <p className="lead-bold">Wir arbeiten noch an einer digitalisierten Anmeldeseite für Helfer.</p>
        <p className="lead-bold">In der Zwischenzeit können Sie uns gerne eine E-Mail schreiben oder uns telefonisch kontaktieren.</p>
        <p className="lead-bold">Kontakt: <a href="mailto:samichlous@samichlous-belp.ch">samichlous@samichlous-belp.ch</a> | Telefon: <a href="tel:0787581651">078 758 16 51</a></p>
        <Link to="/" className="btn btn-light btn-lg btn-transparent">Zurück zur Startseite</Link>
      </div>
      {/* Statische Kontaktleiste */}
      <div className="contact-bar">
        <p>
          Kontakt: &nbsp;  
          <a href="mailto:samichlous@samichlous-belp.ch">samichlous@samichlous-belp.ch</a> | 
          Telefon: <a href="tel:0787581651">078 758 16 51</a>
        </p>
      </div>
    </div>
  );
};

export default Helfer;